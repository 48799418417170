/* global Chartist:true */

'use strict';
(function (window, $) {
    var $searchCustomerForm = $('#jwsdw-searchCustomer-form'),
        $searchCustomerButton = $searchCustomerForm.find('button'),
        $searchCustomerErrorMessage = $searchCustomerForm.find('.jws-formErrorMessage'),
        $priceOverrideForm = $('#jwsdw-priceOverride-form'),
        $priceOverrideButton = $priceOverrideForm.find('button'),
        $priceOverrideButtonText = $priceOverrideButton.find('.jwsdw-buttonText'),
        $priceOverrideSpinner = $priceOverrideButton.find('.jwsdw-spinner'),
        $priceOverrideErrorMessage = $priceOverrideForm.find('.jwsdw-message-error'),
        xhr;

    if (window.jwsdwSettings.isJwAgent) {
        startCountdown();
    }

    /**
     * @description Method to start the countdown of how long 2FA is valid
     * @returns {void}
    */
    function startCountdown() {
        var $countdown = $('.jwsdw-associate-bar-session'),
            timeLeft = 1000 * 60 * 25, // 25 minutes
            interval = setInterval(function() {
                var minutes = Math.floor(timeLeft / (1000 * 60)),
                    seconds = Math.floor((timeLeft - minutes * 1000 * 60) / 1000);

                if (timeLeft < 1000 * 60) {
                    $countdown
                        .removeClass('jws-colorGrey3')
                        .addClass('jws-colorRed');
                }

                $countdown.text(
                    window.jwsdwUtil.padNumber(minutes, 2) +
                    ':' +
                    window.jwsdwUtil.padNumber(seconds, 2)
                );

                timeLeft -= 1000;

                if (timeLeft < 0) {
                    clearInterval(interval);
                    window.jwsdwApi.popup.showHttpError(401, true);
                }
            }, 1000);
    }

    if ($searchCustomerForm.length > 0) {
        $searchCustomerForm.validate();
    }
    if ($priceOverrideForm.length > 0) {
        $priceOverrideForm.validate();
    }

    /**
     * Method to handle form submits
     * @param {Object} event the event that triggered the handler
     * @param {function} successCallback the callback that is triggered when the ajax call was successful
     * @param {function} errorCallback the callback that is triggered when the ajax call was unsuccessful
     * @param {function} beforeSendCallback the callback that is triggered before the ajax call is made
     * @param {function} completeCallback the callback that is triggered after the ajax call was made
     * @param {object} [fieldsToSend] optional fields that will be sent instead of the full form
     * @returns {void}
     */
    function formSubmit (event, successCallback, errorCallback, beforeSendCallback, completeCallback, fieldsToSend) {
        var $this = $(this);

        event.preventDefault();

        // only submit form if frontend validation is successful and no other call is executed
        if (!$this.valid() || (xhr && xhr.readyState !== 4)) {
            return;
        }

        // XHR request to form action url with form fields as parameters
        xhr = $.ajax($this.attr('action'), {
            'method': $this.attr('method') || 'GET',
            'data': !fieldsToSend ? $this.serialize() : fieldsToSend.serialize(),
            'beforeSend': beforeSendCallback,
            'success': successCallback,
            'error': errorCallback,
            'complete': completeCallback
        });
    }

    /**
     * Method to execute if searchCustomer was handled successfully
     * @param {object} data the response data
     * @param {String} data.returnUrl url for the account overview page to redirect the user
     * @returns {void}
     */
    function searchCustomerSuccessCallback (data) {
        $searchCustomerButton.removeAttr('disabled');
        $searchCustomerButton.removeClass('jws-buttonSpinner');

        window.jwsdwMediator.publish('openPicker', 'customerPicker', data);
    }

    /**
     * Method to execute if searchCustomer was not handled successfully
     * @param {object} data the response object containing the error
     * @returns {void}
     */
    function searchCustomerErrorCallback (data) {
        $searchCustomerButton.removeAttr('disabled');
        $searchCustomerButton.removeClass('jws-buttonSpinner');

        // jquery ajax returns a wrapped result
        data = data.responseJSON ? data.responseJSON : {};

        if (data.code && data.code === 'AUTHENTICATION_FAILED') {
            $searchCustomerErrorMessage.text(data.details.message);
            $searchCustomerErrorMessage.removeClass('jws-hidden');
        }
    }


    /**
     * Method to execute the before send logic before the ajax call is made
     * @returns {void}
     */
    function searchCustomerBeforeSendCallback () {
        $searchCustomerErrorMessage.addClass('jws-hidden');
        $searchCustomerButton.attr('disabled', 'disabled');
        $searchCustomerButton.addClass('jws-buttonSpinner');
    }

    /**
     * Method to execute if priceOverride was handled successfully
     * @returns {void}
     */
    function priceOverrideSuccessCallback () {
        $priceOverrideButton.removeAttr('disabled');
        $priceOverrideButtonText.removeClass('jwsdw-invisible');
        $priceOverrideSpinner.addClass('jws-hidden');

        window.jwsdwMediator.publish('updateBasket');
        window.jwsdwMediator.publish('closePicker', 'priceOverridePicker', true);
    }

    /**
     * Method to execute if priceOverride was not handled successfully
     * @param {object} data the response object containing the error
     * @returns {void}
     */
    function priceOverrideErrorCallback (data) {
        $priceOverrideButton.removeAttr('disabled');
        $priceOverrideButtonText.removeClass('jwsdw-invisible');
        $priceOverrideSpinner.addClass('jws-hidden');

        // jquery ajax returns a wrapped result
        data = data.responseJSON ? data.responseJSON : {};

        if (data.details && data.details.message) {
            $priceOverrideErrorMessage.text(data.details.message);
            $priceOverrideErrorMessage.removeClass('jwsdw-invisible');
        }
    }

    /**
     * Method to execute the before send logic before the ajax call is made
     * @returns {void}
     */
    function priceOverrideBeforeSendCallback () {
        $priceOverrideErrorMessage.addClass('jwsdw-invisible');
        $priceOverrideButton.attr('disabled', 'disabled');
        $priceOverrideButtonText.addClass('jwsdw-invisible');
        $priceOverrideSpinner.removeClass('jws-hidden');
    }

    // searchCustomer form submit listener
    $searchCustomerForm.submit(function (e) {
        formSubmit.call(this, e, searchCustomerSuccessCallback, searchCustomerErrorCallback, searchCustomerBeforeSendCallback, null, null);
    });


    $('#jwsdw-priceOverride-delete').click(function() {
        $('#jwsdw-priceOverride-percent').trigger('click');
        $('#jwsdw-priceOverride-value').val(0);
        $('#jwsdw-priceOverride-reason').val('');
        $priceOverrideForm.submit();
    });
    $priceOverrideForm.submit(function (e) {
        formSubmit.call(this, e, priceOverrideSuccessCallback, priceOverrideErrorCallback, priceOverrideBeforeSendCallback, null, null);
    });

    $priceOverrideForm.find('.jws-radio input').on('change', '', function() {
        var label = $(this).next().text();
        $priceOverrideForm.find('label[for=jwsdw-priceOverride-value]').text(label);
        calculateNewPrice();
    });

    $priceOverrideForm.find('input').on('keyup', '', calculateNewPrice);

    /**
     * @description Method to calculate price after discount
     * @returns {void}
     */
    function calculateNewPrice() {
        var oldPrice = $priceOverrideForm.find('input[name=oldPrice]').val(),
            type = $priceOverrideForm.find('input[name=type]:checked').val(),
            value = Number($priceOverrideForm.find('input[name=value]').val().replace(',', '.')) || 0,
            itemPriceOverrideMaxPercent = Number($priceOverrideForm.find('input[name=itemPriceOverrideMaxPercent]').val()),
            newPrice = oldPrice;

        if (type === 'percent') {
            newPrice *= 1 - value / 100;
        } else if (type === 'amount') {
            newPrice -= value;
        } else if (type === 'fixed') {
            newPrice = value;
        }

        $priceOverrideForm.find('#jwsdw-priceOverride-newPrice').text(window.jwsdwUtil.formatPrice(newPrice));

        if (newPrice / oldPrice < 1 - itemPriceOverrideMaxPercent) {
            $priceOverrideForm.find('#jwsdw-priceOverride-newPrice').addClass('jws-colorRed');
        } else {
            $priceOverrideForm.find('#jwsdw-priceOverride-newPrice').removeClass('jws-colorRed');
        }

        $priceOverrideErrorMessage.addClass('jwsdw-invisible');
    }

    // on change listener to remove error message
    if (window.jwsdwApi.barcodeScanner.canUseBarcodeScanner()) {
        $('.jwsdw-scanCustomerCard').removeClass('jws-hidden');
        $searchCustomerForm.on('click', '.jwsdw-scanCustomerCard', function () {
            window.jwsdwApi.barcodeScanner.initBarcodeScanner(
                $(this).data('scanner-text').join('<br/><br/>'),
                function(data) {
                    $('#jwsdw-searchCustomer-query').val(data.codeResult.code);
                    $searchCustomerForm.submit();
                }
            );
        });
    }
    $searchCustomerForm.on('input', 'input#jwsdw-agentsearchCustomer-associateId, input#jwsdw-agentsearchCustomer-password', function () {
        $searchCustomerErrorMessage.addClass('jws-hidden');
    });

    window.jwsdwMediator.subscribe('selectCustomer', function (customer) {
        var params = {
            'customerNumber': customer.customerNumber
        };

        params[window.jwsdwSettings.csrfTokenName] = window.jwsdwSettings.csrfTokenValue;

        $.ajax({
            'url': window.jwsdwSettings.baseUrl + '/EA-loginOnBehalf',
            'method': 'POST',
            'data': params,
            'success': function() {
                window.location.reload();
            }
        });
    });

    $(document).on('click', '.jwsdw-associate-togglePrivacy', function() {
        $('.jwsdw-header-container, #jwsdw-body').toggleClass('jwsdw-blur-10px jwsdw-pointerEvents-none');
    });

    /* resend activation mail */
    $(document).on('submit', '.jwsdw-ea-resendActivationMail', function (event) {
        var $form = $(this),
            $successMessage = $form.next('.jwsdw-ea-resendActivationMail-success');

        event.preventDefault();

        $.ajax({
            'type': $form.attr('method'),
            'url': $form.attr('action'),
            'data': $form.serialize(),
            'success': function () {
                $form.addClass('jws-hidden');
                $successMessage.removeClass('jws-hidden');
            }
        });
    });

}(window, jQuery));

(function (window, $, Chartist) {
    var _timespan = 'today',
        _associate,
        _xhr,
        _startDate,
        _endDate,
        _dayMap = ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
        millisecondsInHour = 1000 * 60 * 60,
        millisecondsInDay = millisecondsInHour * 24,
        options = {
            'seriesBarDistance': 10,
            'axisY': {
                'offset': 100,
                'labelInterpolationFnc': window.jwsdwUtil.formatPrice
            },
            'axisX': {
                'offset': 100
            }
        },
        responsiveOptions = [
            [
                'screen and (max-width: 640px)', {
                    'seriesBarDistance': 5
                }
            ]
        ];

    $.datepicker.setDefaults($.datepicker.regional[window.jwsdwSettings.languageCode]);
    $('#jwsdw-reporting-from').datepicker({
        'showOtherMonths': true,
        'maxDate': new Date(),
        'dateFormat': 'dd.mm.yy',
        'onSelect': updateDate
    });
    $('#jwsdw-reporting-to').datepicker({
        'showOtherMonths': true,
        'minDate': new Date(),
        'maxDate': new Date(),
        'dateFormat': 'dd.mm.yy',
        'onSelect': updateDate
    });

    /**
     * @description Method to load report based on custom date
     * @returns {void}
     */
    function updateDate() {
        $('.jwsdw-salesReport-timespan')
            .removeClass('jws-buttonYellow')
            .addClass('jws-buttonGrey5');

        _timespan = {
            'from': $('#jwsdw-reporting-from').datepicker('getDate').getTime(),
            'to': $('#jwsdw-reporting-to').datepicker('getDate').getTime()
        };

        loadReport(_timespan, _associate);
    }

    $(document).on('change', '#jwsdw-salesReport-associate', function() {
        _associate = $(this).val();

        loadReport(_timespan, _associate);
    });
    $(document).on('click', '.jwsdw-salesReport-timespan', function() {
        _timespan = $(this).data('timespan');
        $('.jwsdw-salesReport-timespan')
            .removeClass('jws-buttonYellow')
            .addClass('jws-buttonGrey5');
        $(this)
            .removeClass('jws-buttonGrey5')
            .addClass('jws-buttonYellow');

        loadReport(_timespan, _associate);
    });

    if ($('.jwsdw-salesReport-timespan[data-timespan=today]').length > 0) {
        $('.jwsdw-salesReport-timespan[data-timespan=today]').click();
    }

    /**
     * @description Method to load report
     * @param {String|Object} timespan selected timespan either as string (e.g. "today") or from-to value pair
     * @param {String} associateId associate Id
     * @returns {void}
     */
    function loadReport(timespan, associateId) {
        var data = {};

        if (typeof timespan === 'string') {
            data.timespan = timespan;
        } else {
            data.from = timespan.from;
            data.to = timespan.to;
        }

        if (associateId) {
            data.associateId = associateId;
        }

        if (_xhr && _xhr.abort) {
            _xhr.abort();
        }

        data[window.jwsdwSettings.csrfTokenName] = window.jwsdwSettings.csrfTokenValue;

        _xhr = $.ajax(window.jwsdwSettings.baseUrl + '/EA-getSalesReport', {
            'method': 'GET',
            'data': data,
            'beforeSend': function () {
                $('#jwsdw-salesReport-chart .jwsdw-spinner').removeClass('jws-hidden');
            },
            'complete': function () {
                $('#jwsdw-salesReport-chart .jwsdw-spinner').addClass('jws-hidden');
            },
            'success': renderReport

        });
    }

    /**
     * @description Method to render sales report chart and totals
     * @param {Object} reportData report data
     * @returns {void}
     */
    function renderReport(reportData) {
        var businessDays,
            today = (new Date()).getTime(),
            lastOrderDay,
            data;

        $('#jwsdw-salesReport-orders').text(reportData.statistics.orders);
        $('#jwsdw-salesReport-orderTotal').text(reportData.statistics.orderTotal);
        $('#jwsdw-salesReport-articlesSold').text(reportData.statistics.articlesSold);
        $('#jwsdw-salesReport-reductions').text(reportData.statistics.reductions);
        $('#jwsdw-salesReport-reductionTotal').text(reportData.statistics.reductionTotal);

        _startDate = reportData.statistics.timespan.from;
        _endDate = reportData.statistics.timespan.to;

        $('#jwsdw-reporting-to').datepicker('setDate', new Date(_endDate));
        $('#jwsdw-reporting-from').datepicker('setDate', new Date(_startDate));
        $('#jwsdw-reporting-to').datepicker('option', 'minDate', new Date(_startDate));
        $('#jwsdw-reporting-from').datepicker('option', 'maxDate', new Date(_endDate));

        if (reportData.orders.length > 0) {
            $('#jwsdw-salesReport-chart .jwsdw-salesReport-noData').addClass('jws-hidden');
        } else {
            $('#jwsdw-salesReport-chart .jwsdw-salesReport-noData').removeClass('jws-hidden');
        }
        businessDays = reportData.orders
            .map(_roundOrderDateToHour)
            .reduce(_fillBlankData, [])
            .map(_parseOrder)
            .reduce(_consolidateOrdersByDays, []);

        if (businessDays.length > 0 && _timespan !== 'today' && !_timespan.hasOwnProperty('to')) {
            // fill blank data until today
            lastOrderDay = businessDays[businessDays.length - 1].timestamp;
            while (lastOrderDay < today) {
                lastOrderDay += millisecondsInDay;

                businessDays.push(_parseOrder({
                    'orderTotal': 0,
                    'date': lastOrderDay
                }));
            }
        }
        data = {
            'labels': businessDays.map(function(day) {
                return day.date;
            }),
            'series': [
                businessDays.map(function(day) {
                    return day.orderTotal;
                })
            ]
        };

        if (data.labels.length > 31) {
            data.labels = data.labels.map(function(label, index) {
                return index % 7 === 0 ? label : '';
            });
        } else if (data.labels.length > 28) {
            data.labels = data.labels.map(function(label, index) {
                return index % 3 === 0 ? label : '';
            });
        }
        new Chartist.Bar( // eslint-disable-line
            '#jwsdw-salesReport-chart',
            data,
            options,
            responsiveOptions
        );
    }

    /**
     * @description Method to round order date to full hour
     * @param {Object} order order object
     * @returns {Object} parsed order
     */
    function _roundOrderDateToHour(order) {
        order.date = Math.floor(order.date / millisecondsInHour) * millisecondsInHour;
        return order;
    }

    /**
     * @description Method to prepare order data as needed
     * @param {Object} order order object
     * @returns {Object} parsed order
     */
    function _parseOrder(order) {
        var date = new Date(order.date);

        order.timestamp = order.date;

        if (_timespan === 'today') {
            order.date = order.date = [
                window.jwsdwUtil.padNumber(date.getHours(), 2),
                window.jwsdwUtil.padNumber(date.getMinutes(), 2)
            ].join(':');
        }
        if (_timespan === 'week') {
            order.date = _dayMap[date.getDay()];
        } else if (_timespan === 'month') {
            order.date = [
                window.jwsdwUtil.padNumber(date.getDate(), 2),
                window.jwsdwUtil.padNumber(date.getMonth() + 1, 2)
            ].join('.');
        } else {
            order.date = [
                window.jwsdwUtil.padNumber(date.getDate(), 2),
                window.jwsdwUtil.padNumber(date.getMonth() + 1, 2)
            ].join('.');
        }

        return order;
    }

    /**
     * @description Method to fill in blank periods
     * @param {Object[]} arr perdios containing consolidated order data
     * @param {Object} order order object
     * @param {Number} index current index
     * @param {Object[]} orders the full array of order objects
     * @returns {Object[]} consolidated orders
     */
    function _fillBlankData(arr, order, index, orders) {
        var period = _timespan === 'today' ? millisecondsInHour : millisecondsInDay,
            currentDate = arr.length > 0 ? Math.round(arr[arr.length - 1].date / period) * period : null,
            orderDate = Math.round(order.date / period) * period;

        if (index === 0) {
            while (orderDate && orderDate > _startDate) {
                arr.push({
                    'date': _startDate,
                    'orderTotal': 0
                });
                _startDate += period;
            }
        }

        while (currentDate && orderDate > currentDate) {
            arr.push({
                'date': currentDate,
                'orderTotal': 0
            });
            currentDate += period;
        }

        if (index === orders.length - 1) {
            while (currentDate && _endDate > currentDate) {
                arr.push({
                    'date': currentDate,
                    'orderTotal': 0
                });
                currentDate += period;
            }
        }

        arr.push(order);
        return arr;
    }

    /**
     * @description Method to consolidate orders into days
     * @param {Object[]} consolidated days containing consolidated order data
     * @param {Object} order order object
     * @returns {Object[]} consolidated orders
     */
    function _consolidateOrdersByDays(consolidated, order) {
        var indexExistingDate = consolidated.findIndex(function(businessDay) {
            return businessDay.date === order.date;
        });

        if (indexExistingDate === -1) {
            consolidated.push(order);
        } else {
            consolidated[indexExistingDate].orderTotal += order.orderTotal;
        }
        return consolidated;
    }
}(window, jQuery, Chartist));
